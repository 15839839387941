import React from 'react';
import '@sendbird/uikit-react/dist/index.css';
import ChatHubComponent from "./components/ChatHubComponent";
import { Routes, Route } from 'react-router-dom';
import SnippetComponent from "./components/SnippetComponent";
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/chat-hub" exact element={<ChatHubComponent />} />
      <Route path="/snippet" element={<SnippetComponent />} />
      <Route path="/" element={<SnippetComponent />} />
    </Routes>
  );
}

export default App;
