import React, { useEffect, useState } from 'react';
import '@sendbird/uikit-react/dist/index.css';
import SendBird from 'sendbird';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';

const SnippetComponent = () => {
    const [channelUrl, setChannelUrl] = useState(null);
    const appId = process.env.REACT_APP_SENDBIRD_APP_ID;    

    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const userIdQuery = queryParams.get('userId'); 
    const channelUrlQuery = queryParams.get('channelUrl');       

    useEffect(() => {        

        if (!userIdQuery || !channelUrlQuery || !appId ) {
            console.error('Missing appId, userId or channelUrl');
            return;
        }
              
        const fetchChannel = async () => {
            const sb = new SendBird({ appId });
            sb.connect(userIdQuery, async (user, error) => { 
                if (error) {
                    console.error('Sendbird connection error:', error);
                    return;
                }                               
                const channel = await sb.GroupChannel.getChannel(channelUrlQuery);
                if(channel) {
                    setChannelUrl(channelUrlQuery);
                }
            });
        };

        fetchChannel();
    }, [appId, userIdQuery]);

    if (!channelUrl) {
        return <div>Loading chat...</div>;
    }

    return (
        <SendbirdProvider
            appId={appId}
            userId={userIdQuery}            
        >
            <GroupChannel 
              channelUrl={channelUrl} 
              className="sendbird-snippet-react"  
              renderChannelHeader={() => null}            
            />
        </SendbirdProvider>
    );
};

export default SnippetComponent;