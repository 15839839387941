import React from 'react';
import { SendbirdProvider } from '@sendbird/uikit-react/SendbirdProvider';
import { GroupChannelList } from "@sendbird/uikit-react/GroupChannelList";
import { GroupChannel } from "@sendbird/uikit-react/GroupChannel";
import '@sendbird/uikit-react/dist/index.css';
import { useState } from 'react';

const ChatHubComponent = () => {
    const colorSet = {
        '--sendbird-light-primary-500': '#066858',
        '--sendbird-light-primary-400': '#027d69',
        '--sendbird-light-primary-300': '#F25621',
        '--sendbird-light-primary-200': '#69c085',
        '--sendbird-light-primary-100': '#a8e2ab',
    };

    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const userId = queryParams.get('userId');
    const nickname = queryParams.get('nickname');

    const [selectedChannelUrl, setSelectedChannelUrl] = useState(null);

    const handleChannelSelect = (channel) => {
        if (channel) {            
            setSelectedChannelUrl(channel.url);
        }
    };

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <SendbirdProvider
                appId={process.env.REACT_APP_SENDBIRD_APP_ID}
                userId={userId}
                nickname={nickname}
                colorSet={colorSet}
            >
                <div style={{ display: 'flex', height: '100%' }}>                    
                    <div style={{ width: '16.6%', borderRight: '1px solid #ccc' }}>
                        <GroupChannelList
                            onChannelSelect={handleChannelSelect} 
                        />
                    </div>
                    
                    <div style={{ width: '84.4%' }}>
                        {selectedChannelUrl ? (
                            <GroupChannel
                                channelUrl={selectedChannelUrl} 
                            />
                        ) : (
                            <div>Select a channel</div>
                        )}
                    </div>
                </div>
            </SendbirdProvider>
        </div>
    );
};

export default ChatHubComponent;
